@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1200px; /* Max width for the main content */
  margin: 0 auto; /* Center the container */
}

section {
  padding: 2rem 0; /* Space between sections */
}

h2 {
  margin-bottom: 1rem; /* Space below headings */
}

.tick-list {
  list-style-type: none; /* Remove default bullets */
  padding: 0; /* Remove padding */
}

.tick-list li {
  position: relative; /* Position relative for pseudo-element */
  padding-left: 25px; /* Space for the tick mark */
  margin-bottom: 10px; /* Space between items */
  line-height: 1.5; /* Adjust line height for better readability */
}

.tick-list li::before {
  content: '✔'; /* Unicode for check mark */
  color: #003459; /* Color of the check mark */
  position: absolute; /* Position absolutely */
  left: 0; /* Align to the left */
  font-size: 20px; /* Size of the check mark */
  line-height: 1.5; /* Match line height of text */
}

.dash-list {
  list-style-type: none; /* Remove default bullets */
  padding: 0; /* Remove padding */
}

.dash-list li {
  position: relative; /* Position relative for pseudo-element */
  padding-left: 25px; /* Space for the tick mark */
}

.dash-list li::before {
  content: '-'; /* Unicode for check mark */
  color: #000000; /* Color of the check mark */
  position: absolute; /* Position absolutely */
  font-size: 1em;
  left: 0; /* Align to the left */
}

/* Default styles for larger screens (desktop) */
.navbar-links {
  display: flex;
  flex-direction: row; /* Horizontal layout */
}

/* Styles for mobile screens */
@media (max-width: 640px) {
  .navbar-links {
    display: flex;
    flex-direction: column; /* Vertical layout */
    align-items: center; /* Center items horizontally */
    width: 100%; /* Optional: Full width for mobile */
  }

  .navbar-links a {
    padding: 10px 0; /* Add padding for better spacing */
    text-align: center; /* Center text */
    width: 100%; /* Full width for each link */
  }

  /* Adjust the logo for mobile */
  img {
    height: auto; /* Maintain aspect ratio */
    max-height: 100px; /* Set a maximum height for mobile */
    width: 100%; /* Ensure it takes full width */
    object-fit: contain; /* Contain the image within the specified dimensions */
  }
}

